.popoverButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popoverButtonContainer > p {
  align-self: flex-end;
}

.popoverWrapper {
  display: none;
}

.popoverWrapperForSettings {
  margin-top: 0.55rem;
}

.popoverForNavBar {
  margin-left: 0.5rem;
}

@media screen and (min-width: 40rem) {
  .popoverWrapper {
    display: block;
  }
}
