/*---------------------------------------------
   BEGIN: The Full calendar parent table styling
---------------------------------------------*/

.container {
  flex-grow: 1;
  overflow: auto;
}

.container :global(.fc .fc-event:focus::after) {
  background: none;
}

/* bypass the styling for focused event */
.container :global(.fc .fc-event:focus) {
  outline: none;
  background-color: none;
}

.container :global(.fc .fc-event.selected) {
  border-radius: var(--space-smallest);
  outline: var(--space-smaller) solid var(--color-interactive);
  background-color: var(--color-surface--active) !important;
  opacity: 0.7;
  transition: background-color 0.3s ease-in-out;
  outline-width: var(--border-thick);
}

/* the non-business hours background styling */
.container :global(.fc .fc-non-business) {
  background-image: linear-gradient(
    141deg,
    rgb(242, 242, 242) 25%,
    rgb(230, 230, 230) 25%,
    rgb(230, 230, 230) 50%,
    rgb(242, 242, 242) 50%,
    rgb(242, 242, 242) 75%,
    rgb(230, 230, 230) 75%,
    rgb(230, 230, 230) 100%
  );
  background-size: 15.89px 12.87px;
}

/* Available slot styling to show/hide the title */
.container :global(.fc .fc-event.available-slot i) {
  visibility: hidden;
}

/* TODO: Implement this when we are able to pass the client name */
/* .container :global(.fc .fc-event.available-slot.selected i) {
  visibility: visible;
} */

.container :global(.fc-scrollgrid) {
  border: none;
}

.container :global(.fc-theme-standard) {
  border-bottom: var(--space-minuscule) solid var(--color-border);
}

.container :global(.fc .fc-day-today) {
  background-color: var(--color-surface);
}

.container :global(.fc .fc-timegrid-slot-minor) {
  border-top: none;
}

/*---------------------------------------------
   END: The Full calendar parent table styling
---------------------------------------------*/

/*---------------------------------------------
   BEGIN The Assignee/Resource Header
---------------------------------------------*/
.container :global(.fc .fc-timegrid-slot-label) {
  padding-right: 5px;
  border: none;
  align-items: "center";
  vertical-align: baseline;
}

.container :global(.fc .fc-resource) {
  font-size: var(--typography--fontSize-base);
  font-weight: 400;
  background-clip: padding-box; /* the background of the element will be visible only within the padding box, which is the area inside the element's border */
}

.container :global(.fc .fc-resource .fc-col-header-cell-cushion) {
  display: block;
  padding: var(--space-small) var(--space-smaller);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* for Chrome, Safari and Opera */
.container
  :global(
    .fc
      table
      > thead
      > tr.fc-scrollgrid-section-header
      > th[role="presentation"]
  ) {
  border: none;
  border-bottom: var(--space-minuscule) solid var(--color-border);
  text-align: left;
}

/* for firefox */
.container
  :global(
    .fc
      table
      > tbody
      > tr.fc-scrollgrid-section-header
      > th[role="presentation"]
  ) {
  border: none;
  border-bottom: var(--space-minuscule) solid var(--color-border);
}

.container :global(.fc th[role="presentation"] th) {
  border: none;
  text-align: left;
}

/* resource/assignee scrollbar removal */

.container
  :global(
    tbody
      .fc-scrollgrid-section-header
      > th[role="presentation"]:nth-child(2)
      > .fc-scroller-harness
      > .fc-scroller
  ) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container
  :global(
    thead
      .fc-scrollgrid-section-header
      > th[role="presentation"]:nth-child(2)
      > .fc-scroller-harness
      > .fc-scroller::-webkit-scrollbar
  ) {
  display: none;
}

/*---------------------------------------------
   END: The Assignee/Resource Header
---------------------------------------------*/

/*---------------------------------------------
   BEGIN: The Full calendar events
---------------------------------------------*/

.container :global(.fc .fc-timegrid-col-events) {
  margin: 0px 7px 0px 6px;
}

.container :global(.fc .fc-timegrid-event-harness) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1px;
  container-type: size;
}

.container :global(.fc .fc-timegrid-event .fc-event-main) {
  display: block;
}

.container
  :global(.fc .fc-timegrid-event.fc-timegrid-event-short .fc-event-main) {
  display: flex;
  align-items: center;
}

.container :global(.fc .fc-event-main i) {
  font-style: normal;
}

.container :global(.fc .fc-timegrid-event) {
  margin-top: var(--space-smallest);
  margin-bottom: var(--space-minuscule);
  border-radius: var(--space-smaller);
}

.container :global(.fc .fc-timegrid-slot) {
  height: 3em;
  border-color: var(--color-surface--background);
}

.container :global(.fc .fc-daygrid-event-harness) {
  margin: 1px 2px 2px 2px !important;
}

.container :global(.fc .fc-timegrid-bg-harness) {
  margin: var(--space-smaller);
}

.container :global(.fc .fc-bg-event) {
  border-color: var(--color-surface);
  border-width: var(--space-minuscule);
  opacity: 1;
}

.container :global(.fc .available-slot) {
  margin: calc(0px - var(--space-smaller)) 0px;
  border-color: var(--color-interactive);
  border-spacing: var(--space-smaller);
  border-style: dashed;
  border-width: 1px;
}

/*---------------------------------------------
   END: The Full calendar events
---------------------------------------------*/

/*---------------------------------------------
   BEGIN: Anytime row overrides
---------------------------------------------*/

.container :global(.fc-theme-standard td) {
  border-bottom: 0;
}

.container :global(.fc-theme-standard td[role="presentation"]:nth-child(2)) {
  border-right: 0;
}

.container :global(.fc table > tbody > tr:nth-child(1) .fc-daygrid-day-events) {
  height: 47px;
  margin-bottom: 0;
}

.container :global(.fc .fc-timegrid-axis-cushion) {
  max-width: 40px;
  font-size: var(--typography--fontSize-small);
  text-align: center;
}

/* The anytime box shadow border */
.container
  :global(
    tbody
      .fc-scrollgrid-section-body
      > td[role="presentation"]:nth-child(2)
      > .fc-scroller-harness.fc-scroller-harness-liquid::before
  ) {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  height: var(--space-minuscule);
  box-shadow: 0 0 5px var(--color-grey--dark);
  border-top: 0;
}

/* Remove the divider between anytime and the timed daygrid */
.container :global(tr.fc-scrollgrid-section:nth-last-child(2)) {
  display: none;
}

.container
  :global(
    tbody
      .fc-scrollgrid-section-body:nth-child(2)
      > td[role="presentation"]:nth-child(2)
      > .fc-scroller-harness
      > .fc-scroller
  ) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container
  :global(
    tbody
      .fc-scrollgrid-section-body:nth-child(1)
      > td[role="presentation"]:nth-child(2)
      > .fc-scroller-harness
      > .fc-scroller::-webkit-scrollbar
  ) {
  display: none;
}

/*---------------------------------------------
   END: Anytime row overrides
---------------------------------------------*/

/*---------------------------------------------
   BEGIN: Timegrid slot width override
---------------------------------------------*/

.container
  :global(
    .fc .fc-resourceTimeGridDay-view > table > colgroup > col:nth-child(1)
  ) {
  width: var(--svl-slotLabel-margin) !important;
}

.container :global(.fc .fc-timegrid-slot-label-frame) {
  font-size: var(--typography--fontSize-small);
  text-align: right;
}

/* time indicator removal of scrollbar from view */
.container
  :global(
    tbody
      .fc-scrollgrid-section-body
      > td[role="presentation"]:nth-child(1)
      > .fc-scroller-harness.fc-scroller-harness-liquid
      > .fc-scroller.fc-scroller-liquid-absolute
  ) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container
  :global(
    tbody
      .fc-scrollgrid-section-body
      > td[role="presentation"]:nth-child(1)
      > .fc-scroller-harness.fc-scroller-harness-liquid
      > .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar
  ) {
  display: none;
}

/*---------------------------------------------
   END: Timegrid slot width override
---------------------------------------------*/

/*---------------------------------------------
   BEGIN: The Full calendar now indicator
---------------------------------------------*/

.container :global(.fc .fc-timegrid-now-indicator-arrow) {
  left: 40px;
}

/*---------------------------------------------
   END: The Full calendar now indicator
---------------------------------------------*/
