.container {
  display: grid;
  grid-column-gap: var(--space-larger);
  grid-row-gap: var(--space-larger);
  grid-template-rows: auto;
  grid-template-areas:
    "bankDetails"
    "notifications"
    "disableJobberPaymentsContainer"
    "jPayDisclosure";
}

.containerWithInsights {
  display: grid;
  grid-column-gap: var(--space-larger);
  grid-row-gap: var(--space-larger);
  grid-template-rows: auto;
  grid-template-areas:
    "financialInsights" /* financialInsights grid area will be included if FinancialInsightsSection is rendered  */
    "bankDetails"
    "notifications"
    "disableJobberPaymentsContainer"
    "jPayDisclosure";
}

.bankDetails {
  grid-area: bankDetails;
}

.notifications {
  grid-area: notifications;
}

.jPayDisclosure {
  grid-area: jPayDisclosure;
}

.disableJobberPaymentsContainer .disableJobberPaymentsDescription {
  margin-bottom: var(--space-small);
  grid-area: disableJobberPaymentsContainer;
}

/* Media query for larger screens (desktop) */
@media (min-width: 1024px) {
  .containerWithInsights {
    grid-template-areas:
      "financialInsights financialInsights"
      "notifications bankDetails"
      "disableJobberPaymentsContainer bankDetails"
      "jPayDisclosure bankDetails";
  }

  .container {
    grid-template-areas: "notifications bankDetails" "disableJobberPaymentsContainer bankDetails" "jPayDisclosure bankDetails";
  }
}

@media (max-width: 40rem) {
  .pageWrapper h1 {
    display: none;
  }
}
